<template>
  <div class="newmessage-modal">
    <div class="green-circle-button close-button" @click="closeModal">
      <span>X</span>
    </div>
    <div class="title-newmessage-modal">
      <span>Send a new message to</span>
      {{recepient.name.firstName}} {{recepient.name.lastName}}
    </div>

    <input class="v-select" v-model="subject" placeholder="Subject (optional)"/>

    <wysiwyg v-model="body" />

    <div class="cont-btn">
      <div class="btn-green radius" @click="submit" v-if="!sending">Send message</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'newMessageModal',
  props: ['recepient'],
  data() {
    return {
      subject: '',
      body: '',
      sending: false,
    };
  },

  methods: {
    submit() {
      if (this.body !== '') {
        const message = {
          body: this.body,
        };
        if (this.subject) {
          message.subject = this.subject;
        }
        this.sending = true;
        this.$store.dispatch('user/sendMessage', {
          message,
          recepientId: this.recepient._id,
        }).then((msg) => {
          this.$store.dispatch('user/selectConversation', msg._id);
          this.$router.push('/messages');
          this.$modal.hide('new-message');
        });
      }
    },
    closeModal() {
      this.$modal.hide(this.modalName ? this.modalName : 'new-message');
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/_variables.scss";
  .newmessage-modal{
    padding: 20px;
    .title-newmessage-modal{
      font-size: $stitre_fs;
      font-family: 'MontserratBold';
      span{
        display: block;
        font-size: $label_min_fs;
      }
    }
    .v-select{
      margin: 20px 0 10px 0;
    }
  }
</style>
