<template>
<div class="component-fullwidth">
    <div class="subtitle-page"> Posts </div>
    <!-- <div>
      <label>New Post</label>
      <input v-model="postText" />
      <button @click="submitPost">Submit</button>
    </div> -->
    <div class="inner-feed-entry" v-if="loaded">
        <post-view v-for="post in posts" :key="post._id" :post="post" :viewonly="false"
          @updateLike="(data) => updateLike(post._id, data)" />
    </div>
    <loading v-else />
  </div>

</template>

<script>
import Loading from '@/components/utilityPartials/_loading.vue';
import PostView from './_post.vue';

export default {
  name: 'UserPosts',
  props: ['ownerId', 'ownerType'],
  components: {
    Loading,
    PostView,
  },
  data() {
    return {
      posts: [],
      loaded: false,
      endOfList: false,
    };
  },
  mounted() {
    this.$api.get(`feed/posts/${this.ownerType}/${this.ownerId}`).then((res) => {
      this.posts = res.data;
      this.loaded = true;
    });
  },
  methods: {
    updateLike(id, data) {
      const postIndex = this.posts.findIndex((post) => post._id === id);
      if (postIndex > -1) {
        this.posts[postIndex].likes = data.likes;
        this.posts[postIndex].youLiked = data.youLiked;
      }
    },
  },

};
</script>

<style lang="scss" scoped>
  @import "@/assets/scss/_variables.scss";
  // .inner-feed-entry{
  //   font-family: 'Montserrat';
  //   display: flex;
  //   flex-direction: row;
  //   flex-wrap: wrap;
  //   align-items: flex-start;
  //   justify-content: flex-start;
  // }
</style>
