<template>
  <div>
    <div class="subtitle-page">
      Projects
    </div>
    <div class="cont_project_entry">
      <resume-entry
        v-for="(projectEntry, index) in projectEntries"
        :key="projectEntry._id"
        :type="'projects'"
        :objectData="projectEntry"
        :editable="editable"
        :index="index"
      />
    </div>
    <loading v-if="loading" />
  </div>
</template>

<script>
import ResumeEntry from '@/components/TalentComponents/resume/ResumeEntry.vue';
import Loading from '@/components/utilityPartials/_loading.vue';

export default {
  name: 'Resume',
  props: ['editable', 'talentInfo'],
  components: {
    ResumeEntry,
    Loading,
  },

  computed: {
    // academicEntries() {
    //   if (this.editable) {
    //     return this.$store.getters['talent/academicBG'];
    //   }
    //   return this.talentInfo.academicBG;
    // },
    projectEntries() {
      if (this.editable) {
        return this.$store.getters['talent/projects'];
      }
      // return this.talentInfo.projects;
      const elemProject = this.talentInfo.projects;
      elemProject.sort((a, b) => new Date(b.dates.start) - new Date(a.dates.start));
      return elemProject;
    },
    // professionalEntries() {
    //   if (this.editable) {
    //     return this.$store.getters['talent/professionalBG'];
    //   }
    //   return this.talentInfo.professionalBG;
    // },
    loading() {
      if (this.editable && !this.$store.state.talent.resumeRetrieved) {
        return true;
      }
      return false;
    },
  },

  methods: {
    addNew(type) {
      this.$store.dispatch('talent/addBlankResume', type);
    },

  },

};
</script>

<style lang="scss" scoped>
  .cont_project_entry{
    // margin-top: 1vh;
  }
</style>
