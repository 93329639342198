<template>
  <div>
    <div class="subtitle-page">
      Degrees
    </div>
    <div class="cont_academic_entry">
      <resume-entry
        v-for="(academicEntry, index) in academicEntries"
        :key="academicEntry._id"
        :type="'academicBG'"
        :objectData="academicEntry"
        :editable="editable"
        :index="index"
      />
    </div>
    <loading v-if="loading" />
  </div>
</template>

<script>
import ResumeEntry from '@/components/TalentComponents/resume/ResumeEntry.vue';
import Loading from '@/components/utilityPartials/_loading.vue';

export default {
  name: 'Resume',
  props: ['editable', 'talentInfo'],
  components: {
    ResumeEntry,
    Loading,
  },

  computed: {
    academicEntries() {
      if (this.editable) {
        return this.$store.getters['talent/academicBG'];
      }
      // return this.talentInfo.academicBG;
      const elemAca = this.talentInfo.academicBG;
      elemAca.sort((a, b) => new Date(b.dates.start) - new Date(a.dates.start));
      return elemAca;
    },
    // projectEntries() {
    //   if (this.editable) {
    //     return this.$store.getters['talent/projects'];
    //   }
    //   return this.talentInfo.projects;
    // },
    // professionalEntries() {
    //   if (this.editable) {
    //     return this.$store.getters['talent/professionalBG'];
    //   }
    //   return this.talentInfo.professionalBG;
    // },
    loading() {
      if (this.editable && !this.$store.state.talent.resumeRetrieved) {
        return true;
      }
      return false;
    },
  },

};
</script>

<style lang="scss" scoped>
  @import "@/assets/scss/_variables.scss";
  .cont_academic_entry{
    margin-top: 1vh;
  }
</style>
