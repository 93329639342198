<template>
  <div class="component-fullwidth">
    <div class="flex-container flex-full no-padding no-margin">
      <div class="elem-flex">
        <div class="title-page wother">
          {{talent.name.firstName}} {{talent.name.lastName}}
          <div class="other">
            <follow-button
              v-if="!isMe"
              :type="'User'"
              :accountId="talent._id"
            />
            <div class="cont-talent-contact">
              <div class="btn-green radius-small"
                @click="openMessageModal"
                v-if="!isMe"
              >
                Send a message
              </div>

              <div class="btn-green radius-small"
                @click.exact="() => switchTab(`talent-profile`)"
                @click.ctrl="() => switchNewTab(`talent-profile`)"
                @auxclick="() => switchNewTab(`talent-profile`)"
                v-else
              >
                Edit profile
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="inner-container cont-profile-talent">
      <div class="flex-container flex-2-tier-sdlarge">
        <div class="elem-flex padRight mhspe flex-overflow-column">
          <div class="selecteur align-left">
            <div
              class="elem-select"
              :class="select_left === 'about me' ? 'selection' : ''"
              @click="select_left = 'about me'"
            >
              About me
            </div>
            <!-- <div
              class="elem-select"
              :class="select_left === 'project' ? 'selection' : ''"
              @click="select_left = 'project'"
            >
              Projects
            </div> -->
          </div>
          <div class="container-selected">
            <div class="cont-aboutme" v-if="select_left === 'about me'">
              <div class="flex-container flex-2-tier">
                <div class="elem-flex big-left radius box-shadow">
                  <div class="info-about-me">
                    <div class="about-me-picture"
                      v-if="talent.profilePicture"
                      :style="`background-image:url(${talent.profilePicture});`"
                    >
                    </div>
                    <div class="about-me-picture bis"
                      v-else
                      :style="`background-image:url(${require('@/assets/temp/default-user.png')});`"
                    ></div>
                    <div class="about-me-user">
                      <div class="elem-me wanted">
                        <span>Job wanted</span>
                        <p v-if="talent.jobWanted">{{talent.jobWanted}}</p>
                        <p v-else>No Information</p>
                      </div>
                      <div class="elem-me localisation">
                        <span>Lives in</span>
                        <p v-if="talent.location">{{talent.location}}</p>
                        <p v-else>No Information</p>
                      </div>
                      <div class="elem-me desc">
                        <span>My website</span>
                        <a target="_blank"
                          v-if="talent.website"
                          :href="`${talent.website}`"
                        >
                          {{talent.website}}
                        </a>
                        <a
                          v-else
                        >
                          No Information
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="elem-flex relative">
                  <div class="subtitle-talent violet">
                    Social
                  </div>
                  <div id="dash-social-splide"
                    v-if="getOnlyCompleteRS.length > 0"
                    class="radius"
                  >
                    <splide class="list-splide-social"
                      v-if="getOnlyCompleteRS.length <= 1"
                      :options="optionsSocialSolo"
                    >
                      <splide-slide
                        class="elem-splide-social"
                        v-for="event in getOnlyCompleteRS"
                        :key="event._id"
                        @click="redirect(event.link)"
                      >
                        <img
                          v-if="event.site === 'Facebook'"
                          :src="require('@/assets/pictosocial/social-facebook-violet.svg')"
                        >
                        <img
                          v-else-if="event.site === 'Instagram'"
                          :src="require('@/assets/pictosocial/social-instagram-violet.svg')"
                        >
                        <img
                          v-else-if="event.site === 'Twitter'"
                          :src="require('@/assets/pictosocial/social-twitter-violet.svg')"
                        >
                        <img
                          v-else-if="event.site === 'Linkedin'"
                          :src="require('@/assets/pictosocial/social-linkedin-violet.svg')"
                        >
                        <img
                          v-else-if="event.site === 'Github'"
                          :src="require('@/assets/pictosocial/social-github-violet.svg')"
                        >
                        <img
                          v-else-if="event.site === 'Gitlab'"
                          :src="require('@/assets/pictosocial/social-gitlab-violet.svg')"
                        >
                        <img
                          v-else-if="event.site === 'Website'"
                          :src="require('@/assets/pictosocial/social-web-violet.svg')"
                        >
                      </splide-slide>
                    </splide>
                    <splide class="list-splide-social"
                      v-else
                      :options="optionsSocialMultiple"
                    >
                      <splide-slide
                        class="elem-splide-social"
                        v-for="event in getOnlyCompleteRS"
                        :key="event._id"
                      >
                        <img
                          v-if="event.site === 'Facebook'"
                          :src="require('@/assets/pictosocial/social-facebook-violet.svg')"
                          @click="redirect(event.link)"
                        >
                        <img
                          v-else-if="event.site === 'Instagram'"
                          :src="require('@/assets/pictosocial/social-instagram-violet.svg')"
                          @click="redirect(event.link)"
                        >
                        <img
                          v-else-if="event.site === 'Twitter'"
                          :src="require('@/assets/pictosocial/social-twitter-violet.svg')"
                          @click="redirect(event.link)"
                        >
                        <img
                          v-else-if="event.site === 'Linkedin'"
                          :src="require('@/assets/pictosocial/social-linkedin-violet.svg')"
                          @click="redirect(event.link)"
                        >
                        <img
                          v-else-if="event.site === 'Github'"
                          :src="require('@/assets/pictosocial/social-github-violet.svg')"
                          @click="redirect(event.link)"
                        >
                        <img
                          v-else-if="event.site === 'Gitlab'"
                          :src="require('@/assets/pictosocial/social-gitlab-violet.svg')"
                          @click="redirect(event.link)"
                        >
                        <img
                          v-else-if="event.site === 'Website'"
                          :src="require('@/assets/pictosocial/social-web-violet.svg')"
                          @click="redirect(event.link)"
                        >
                      </splide-slide>
                    </splide>
                  </div>
                  <div id="dash-social-splide"
                    v-else
                    class="radius"
                  >
                    <splide class="list-splide-social"
                      v-if="getOnlyCompleteRS.length <= 1"
                      :options="optionsSocialSolo"
                    >
                      <splide-slide
                        class="elem-splide-social"
                      >
                        <div style="height:15vh; font-size:1.6vh;display:flex;align-items:center;">
                          No Social
                        </div>
                      </splide-slide>
                    </splide>
                  </div>
                </div>
              </div>
              <div class="flex-container flex-2-tier reverse">
                <div class="elem-flex radius bgdark">
                  <div class="subtitle-talent green">
                    Skills
                  </div>
                  <div class="content-skills-talent" v-if="talent.skills.length > 0">
                    <div
                      class="elem-skill-talent"
                      v-for="item in talent.skills"
                      :key="item.id"
                    >
                      {{item}}
                    </div>
                  </div>
                  <div class="content-skills-talent" v-else>
                    <div
                      class="elem-skill-talent"
                      style="text-transform:unset;"
                    >
                      No Skill
                    </div>
                  </div>
                </div>
                <div class="elem-flex big-right radius bggrey">
                  <div class="subtitle-talent violet">
                    Technologies
                  </div>
                  <div id="dash-techno-splide"
                    v-if="talent.technologies.length > 0"
                    class="radius"
                  >
                    <splide class="list-splide-techno"
                      v-if="talent.technologies.length <= 7"
                      :options="optionsTechnoSolo"
                    >
                      <splide-slide
                        class="elem-splide-techno"
                        v-for="techno in talent.technologies"
                        :key="techno._id"
                      >
                        <div
                          class="label"
                        >
                          <img
                            v-if="getPictoTechno(techno)"
                            :src="require(`@/assets/pictos/${getPictoTechno(techno)}.svg`)"
                          >
                          <img
                            v-else
                            :src="require(`@/assets/pictos/technos/unspecified.svg`)"
                          >
                          <div class="techno-name">
                            {{techno}}
                          </div>
                        </div>
                      </splide-slide>
                    </splide>
                    <splide class="list-splide-techno"
                      v-else
                      :options="optionsTechnoMultiple"
                    >
                      <splide-slide
                        class="elem-splide-techno"
                        v-for="techno in talent.technologies"
                        :key="techno._id"
                      >
                        <div
                          class="label"
                        >
                          <img
                            v-if="getPictoTechno(techno)"
                            :src="require(`@/assets/pictos/${getPictoTechno(techno)}.svg`)"
                          >
                          <img
                            v-else
                            :src="require(`@/assets/pictos/technos/unspecified.svg`)"
                          >
                          <div class="techno-name">
                            {{techno}}
                          </div>
                        </div>
                      </splide-slide>
                    </splide>
                  </div>
                  <div class="radius" v-else>
                    <splide class="list-splide-techno"
                      v-if="talent.technologies.length <= 7"
                      :options="optionsTechnoSolo"
                    >
                      <splide-slide
                        class="elem-splide-techno"
                      >
                        <div
                          class="label"
                        >
                          <img
                            :src="require(`@/assets/pictos/technos/unspecified.svg`)"
                          >
                          <div class="techno-name">
                            No Techno
                          </div>
                        </div>
                      </splide-slide>
                    </splide>
                  </div>
                </div>
              </div>
            </div>
            <div class="cont-posts">
              <div class="subtitle-page no-margin">
                Last post published
              </div>
              <div class="dash-feed-splide talentvie"
                v-if="posts.length > 0"
              >
                <splide class="list-splide-feed dash-splide"
                  v-if="posts.length <= 3"
                  :options="optionsFeedSolo"
                >
                  <splide-slide
                    class="elem-splide-feed box-shadow radius-small"
                    v-for="postFeed in posts"
                    :key="postFeed._id"
                  >
                    <post-view :post="postFeed" />
                  </splide-slide>
                </splide>
                <splide class="list-splide-feed dash-splide"
                  v-else
                  :options="optionsFeedMultiple"
                >
                  <splide-slide
                    class="elem-splide-feed box-shadow radius-small"
                    v-for="postFeed in posts.slice(0, 9)"
                    :key="postFeed._id"
                  >
                    <post-view :post="postFeed" />
                  </splide-slide>
                </splide>
              </div>
            </div>
          </div>
        </div>
        <div class="elem-flex mhspe78 flex-overflow-column">
          <div class="selecteur">
            <div
              class="elem-select"
              :class="select_right === 'experience' ? 'selection' : ''"
              @click="select_right = 'experience'; pslistBGA.update();"
            >
              Experiences
            </div>
            <div
              class="elem-select"
              :class="select_right === 'degree' ? 'selection' : ''"
              @click="select_right = 'degree'; pslistBGA.update();"
            >
              Degrees
            </div>
            <div
              class="elem-select"
              :class="select_right === 'project' ? 'selection' : ''"
              @click="select_right = 'project'; pslistBGA.update();"
            >
              Projects
            </div>
            <div
              class="elem-select"
              :class="select_right === 'certif' ? 'selection' : ''"
              @click="select_right = 'certif'; pslistBGA.update();"
            >
              Certifications
            </div>
          </div>
          <div class="container-selected">
            <div
              id="listBGA"
              class="inner-list list-current-job flex-verti"
              v-if="select_right === 'experience'"
            >
              <div class="radius contentbox" v-if="talent.professionalBG.length > 0">
                <ProfessionalBG :talentInfo="talent"></ProfessionalBG>
              </div>
              <div class="radius contentbox" v-else>
                No Experience
              </div>
            </div>
            <div
              id="listBGA"
              class="inner-list list-current-job flex-verti"
              v-if="select_right === 'degree'"
            >
              <div class="radius contentbox" v-if="talent.academicBG.length > 0">
                <AcademicBG :talentInfo="talent"></AcademicBG>
              </div>
              <div class="radius contentbox" v-else>
                No Degree
              </div>
            </div>
            <div
              id="listBGA"
              class="inner-list list-current-job flex-verti"
              v-if="select_right === 'project'"
            >
              <div class="radius contentbox" v-if="talent.projects.length > 0">
                <ProjectBG :talentInfo="talent"></ProjectBG>
              </div>
              <div class="radius contentbox" v-else>
                No Project
              </div>
            </div>
            <div
              id="listBGA"
              class="inner-list list-current-job flex-verti"
              v-if="select_right === 'certif'"
            >
              <div class="radius contentbox" v-if="certifications.length > 0">
                <certification v-for="certification in certifications"
                  :key="certification._id"
                  :certification="certification"
                  :editable="false"
                />
              </div>
              <div class="radius contentbox" v-else>
                No Certification
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { mapState } from 'vuex';
import PerfectScrollbar from 'perfect-scrollbar';
import { Splide, SplideSlide } from '@splidejs/vue-splide';
import Resume from '@/views/Talent/TalentDashboard/Resume.vue';
import AcademicBG from '@/views/Talent/TalentProfile/AcademicBG.vue';
import ProfessionalBG from '@/views/Talent/TalentProfile/ProfessionalBG.vue';
import ProjectBG from '@/views/Talent/TalentProfile/ProjectBG.vue';
// import TalentSkills from '@/components/UniversalComponents/TalentView/TalentSkills.vue';
import FeedView from '@/components/UniversalComponents/Feed/userPosts.vue';
import MessageModal from '@/components/UniversalComponents/Messages/newMessageModal.vue';
import Certification from '@/components/TalentComponents/talentSettings/_certification.vue';
import FollowButton from '@/components/UniversalComponents/Feed/following/followButton.vue';
import technoList from '@/components/TalentComponents/talentTechnos/talentTechnoList';
import PostView from '@/components/UniversalComponents/Feed/_post.vue';

const views = [Resume];
export default {
  name: 'TalentView',
  components: {
    // Loading,
    // TalentBio,
    // TalentSkills,
    // FeedView,
    PostView,
    ProfessionalBG,
    ProjectBG,
    AcademicBG,
    Certification,
    FollowButton,
    Splide,
    SplideSlide,
    // SimpleNavIntern,
  },

  data() {
    return {
      loading: true,
      talent: {},
      navIndex: 1,
      componentList: [
        {
          comp: ProfessionalBG,
          picto: 'picto_experience',
          props: {
          },
        },
        {
          comp: AcademicBG,
          picto: 'picto_diploma',
          props: {
          },
        },
        {
          comp: ProjectBG,
          picto: 'picto_project',
          props: {
          },
        },
        {
          comp: FeedView,
          picto: 'picto_feed',
          props: {
            ownerId: this.$route.params.id,
            ownerType: 'Talent',
          },
        },
      ],
      select_left: 'about me',
      select_right: 'experience',
      pslistBGA: '',
      posts: [],
      optionsFeedSolo: {
        perPage: 3,
        gap: '2vh',
        padding: '1vh',
        rewind: true,
        arrows: false,
        pagination: false,
        drag: false,
      },
      optionsFeedMultiple: {
        perPage: 3,
        perMove: 1,
        gap: '2vh',
        padding: '1vh',
        rewind: true,
        drag: false,
      },
      optionsSocialSolo: {
        perPage: 1,
        rewind: true,
        pagination: false,
        arrows: false,
        drag: false,
        autoWidth: false,
        autoHeight: false,
        autoplay: true,
      },
      optionsSocialMultiple: {
        perPage: 1,
        rewind: true,
        pagination: false,
        drag: false,
        autoWidth: false,
        autoHeight: false,
        autoplay: true,
      },
      optionsTechnoSolo: {
        perPage: 7,
        perMove: 1,
        rewind: true,
        padding: '1vh',
        pagination: false,
        arrows: false,
        drag: false,
        autoplay: true,
        gap: '1vh',
      },
      optionsTechnoMultiple: {
        perPage: 7,
        perMove: 1,
        rewind: true,
        padding: '1vh',
        pagination: false,
        drag: false,
        autoplay: true,
        gap: '1vh',
      },
    };
  },
  computed: {
    // ...mapState('feed/myFeed', ['feedPosts']),
    feedPosts() {
      const tempFeed = this.$store.state.feed.myFeed.feedPosts;
      return tempFeed.slice(0, 9);
    },
    leftComponent() {
      const index = (this.navIndex - 1) >= 0
        ? this.navIndex - 1
        : (views.length - 1);
      return views[index];
    },
    rightComponent() {
      return views[this.navIndex];
    },
    isMe() {
      return this.$store.getters['auth/loggedIn']
      && this.$store.state.user._id === this.$route.params.id;
    },
    canMessage() {
      if (!this.$store.getters['auth/loggedIn']) {
        return false;
      }
      if (this.isMe) {
        return false;
      }
      return true;
    },
    getOnlyCompleteRS() {
      return this.talent.socials.filter((u) => u.link !== undefined);
    },
    certifications() {
      const certifs = this.talent.certifications;
      return certifs.sort((a, b) => new Date(a.issueDate) - new Date(b.issueDate));
    },
  },
  mounted() {
    // this.$store.dispatch('feed/myFeed/getPosts');
    setTimeout(() => {
      this.pslistBGA = new PerfectScrollbar('#listBGA', {
        wheelPropagation: false,
      });
      this.pslistBGA.update();
    }, 1500);
    this.$api.get(`talentInfo/${this.$route.params.id}`).then((res) => {
      this.loading = false;
      this.talent = res.data;
      this.getPost();
    });
    // this.getInfo();
  },
  methods: {
    getPost() {
      console.log('this.talent._id : ', this.talent._id);
      this.$api.get(`feed/posts/Talent/${this.talent._id}`).then((res) => {
        this.posts = res.data;
      });
    },
    changeTab(index) {
      this.navIndex = index;
    },
    getLinkRight(link) {
      if (link.includes('https://') || link.includes('http://')) {
        return link;
      }
      return `https://${link}`;
    },
    openMessageModal() {
      this.$modal.show(MessageModal, {
        recepient: this.talent,
      }, {
        name: 'new-message',
        width: '50%',
        height: '500px',
        scrollable: true,
        adaptive: true,
        classes: ['overflow'],
      });
    },
    switchTab(value) {
      this.$router.push(`/${value}`).catch(() => {});
    },
    switchNewTab(value) {
      window.open(`${window.location.origin}/${value}`, '_blank');
    },
    redirect(value) {
      window.open(value, '_blank');
    },
    getPictoTechno(tech) {
      if (this.findByMatchingProperties(technoList, { text: tech }).length > 0) {
        return this.findByMatchingProperties(technoList, { text: tech })[0].picto;
      }
      return false;
    },
    findByMatchingProperties(set, properties) {
      return set.filter((entry) => Object.keys(properties).every(
        (key) => entry[key] === properties[key],
      ));
    },
  },
  watch: {
    talent(newValue) {
      for (let i = 0; i <= 2; i += 1) {
        this.componentList[i].props.talentInfo = newValue;
      }
    },
  },
};
</script>

<style lang="scss">
  @import "@/assets/scss/_splide.scss";
  @import "@/assets/scss/_variables.scss";

  .title-page{
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    color: $logo_color;
  }

  .cont-profile-talent{
    .padRight{
      padding-right: 0vh;
    }
  }

  #listBGA{
    height: 70vh;
    .subtitle-page{
      display: none;
    }
    .cont_experiences_entry, .cont_academic_entry{
      margin-top: 0;
    }
    .ResumeEntry, .cert-view{
      margin-bottom: 1vh;
      .cont-btn{
        display: none;
      }
      &:first-child{
        margin-bottom: 1vh !important;
      }
      &:last-child{
        margin-bottom: 0 !important;
      }
    }
    .ap-view{
      border: none;
      border-radius: 1vh;
    }
  }

  .bgdark{
    background-color: $text_color;
  }

  .bggrey{
    background-color: $fond_light_gray;
  }

  .container-selected{
    width: 100%;
    .flex-container{
      align-items: unset;
      width: 100%;
      margin-bottom: 2vh;
      .elem-flex{
        position: relative;
        overflow: hidden;
        flex: 1 auto;
        &.big-left{
          margin-right: 4vh;
        }
        &.big-right{
          margin-left: 4vh;
        }
        &.relative{
          position: relative;
        }
        .subtitle-talent{
          position: absolute;
          top: 2vh;
          left: 2vh;
          font-family: 'MontserratBold';
          font-size: 1.6vh;
          &.violet{
            color: $logo_color;
          }
          &.green{
            color: $green_color;
          }
        }
      }
    }
  }

  .content-skills-talent{
    display:flex;
    flex-wrap: wrap;
    padding: 2vh;
    margin-top: 3vh;
    width: calc(100% - 4vh);
    .elem-skill-talent{
      text-transform: uppercase;
      font-size: 1.6vh;
      font-family: 'MontserratBold';
      margin-right: 0.5vh;
      color: #ffffff;
      &:nth-child(even){
        color: $logo_color;
      }
    }
  }

  .info-about-me{
    width: 100%;
    height: 100%;
    background-color: $logo_color;
    color: #ffffff;
    display: flex;
    flex:1;
    .about-me-picture{
      flex: 0.60;
      background-size: 100% auto;
      background-position: 50% 50%;
      background-color: #e8e8e8;
    }
    .about-me-user{
      flex: 0.4;
      padding: 2vh;
      font-size: 1.2vh;
      font-family: 'MontserratBold';
      display: flex;
      flex-direction: column;
      justify-content: center;
      span{
        display: block;
        font-family: 'MontserratRegular';
        margin-bottom: 0.5vh;
        line-height: 1;
      }
      .elem-me{
        margin-bottom: 1.5vh;
        &:last-child{
          margin-bottom: 0;
        }
      }
    }
  }
  .cont-posts{
    margin-top: 2vh;
    .splide__track{
      padding: 0.5vh 0vh 0.5vh 0.5vh;
    }
    .elem-feed{
      // max-height: 29vh;
      .top-feed{
        width: calc(100% - 1vh);
        max-height: 19vh;
        padding: 0 0.5vh 0.5vh 0.5vh;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        .visual-feed{
          padding: 0;
          width: 100%;
          max-height: 18vh;
          height: 100%;
          img{
            width: 100%;
            height: auto;
          }
          video{
            width: 100%;
            height: 100%;
          }
        }
        .head-feed{
          // max-height: 25vh;
          // overflow: hidden;
          // text-overflow: ellipsis;
        }
      }
      .footer-feed{
        .elem-footer{
          .pic-name{
            display: none !important;
          }
          .elem-lightbulb{
            align-self: flex-end;
            justify-self: flex-end;
            position: relative;
            top: unset;
            right: unset;
            transform: unset;
            display:flex;
            align-items: center;
            flex-wrap: wrap;
            .label{
              margin-left: 0.5vh
            }
          }
        }
      }
    }
  }
</style>

<style lang='scss'>
@import "@/assets/scss/_variables.scss";
.elem_techno{
  margin-right: 0.5vh;
  margin-bottom: 0.5vh;
}
.push_global_info{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  .left_part{
    width: calc(50% - 10px);
  }
  .right_part{
    display: flex;
    width: calc(50% - 30px);
    padding-left: 30px;

  }
}
</style>

<style lang="scss">
  @import "@/assets/scss/_variables.scss";

  .resume-profile{
    .block-info-basic{
      margin-bottom: 1vh;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap;
      .cont-logo{
        width: 9.75vh;
        height: 9.75vh;
        border-radius: 50%;
        background-color: #ffffff;
        background-size: cover;
        border: 0.25vh solid #ffffff;
      }
    }
  }
</style>

<style lang="scss">
.talentvie {
  width: 100%;
  display: block;
  .list-splide-feed {
    // padding: 0.5vh;
    display: block;
    .elem-splide-feed {
      position: relative;
      background-color: #ffffff;
      padding: 1vh;
      max-height: 31vh !important;
      overflow: hidden;
      .elem-feed {
        // display: block;
      }
    }
  }
  .splide__track {
    padding: 0.5vh 0 !important;
    // padding: 1vh;
  }
}
</style>
