<template>
  <div>
    <div class="subtitle-page">
      Experiences
    </div>
    <div class="cont_experiences_entry">
      <resume-entry
        v-for="(professionalEntry, index) in professionalEntries"
        :key="professionalEntry._id"
        :type="'professionalBG'"
        :objectData="professionalEntry"
        :editable="editable"
        :index="index"
      />
    </div>
    <loading v-if="loading" />
  </div>
</template>

<script>
import ResumeEntry from '@/components/TalentComponents/resume/ResumeEntry.vue';
import Loading from '@/components/utilityPartials/_loading.vue';

export default {
  name: 'Resume',
  props: ['editable', 'talentInfo'],
  components: {
    ResumeEntry,
    Loading,
  },

  computed: {
    // academicEntries() {
    //   if (this.editable) {
    //     return this.$store.getters['talent/academicBG'];
    //   }
    //   return this.talentInfo.academicBG;
    // },
    // projectEntries() {
    //   if (this.editable) {
    //     return this.$store.getters['talent/projects'];
    //   }
    //   return this.talentInfo.projects;
    // },
    professionalEntries() {
      if (this.editable) {
        return this.$store.getters['talent/professionalBG'];
      }
      const elemPro = this.talentInfo.professionalBG;
      elemPro.sort((a, b) => new Date(b.dates.start) - new Date(a.dates.start));
      return elemPro;
      // return this.talentInfo.professionalBG;
      // return this.talentInfo.professionalBG;
    },
    loading() {
      if (this.editable && !this.$store.state.talent.resumeRetrieved) {
        return true;
      }
      return false;
    },
  },

  methods: {
    addNew(type) {
      this.$store.dispatch('talent/addBlankResume', type);
    },

  },

};
</script>

<style lang="scss" scoped>
  @import "@/assets/scss/_variables.scss";
  .cont_experiences_entry{
    margin-top: 1vh;
  }
</style>
