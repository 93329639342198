<template>
  <div class="ResumeEntry">
    <!-- View for Certificate -->
    <div class="cert-view radius box-shadow" v-if="!editing">

      <div class="label">
        {{this.certification.name}}
      </div>
      <div class="from">
        {{this.certification.organization}} - <a v-if="this.certification.url"
          :href="this.certification.url" target="_blank"> {{this.certification.url}}</a>
      </div>
      <div class="cont-date">
        <div class="date date-expl radius-small" v-if="this.certification.issueDate">
          Issued: {{this.certification.issueDate | moment('MM/YY')}}
        </div>
        <div class="to" v-if="this.certification.expirationDate && this.certification.issueDate">
          >
        </div>
        <div class="date date-expl radius-small" v-if="this.certification.expirationDate">
          Expires: {{this.certification.expirationDate| moment('MM/YY')}}
        </div>
      </div>

      <div class="global-param" v-if="editable">
        <div class="param ghost"></div>
        <div class="param" >
          <img src="@/assets/pictos/picto_param_purple.svg">
          <img class="hover" src="@/assets/pictos/picto_param_purple_full.svg">
        </div>
        <div class="cont-param" v-if="editable">
          <div class="elem-param" @click="toggleEdit">Edit certif.</div>
          <div class="elem-param delete" @click="deleteCertification">Remove certif.</div>
        </div>
      </div>
    </div>
    <div class="cert-edit" v-if="editing">
      <div class="cont-form open">
        <div class="elem-form">

          <input class="v-select"
            v-model="certificationData.name" placeholder="Certification Name"
            requiredVal="certificationData.name" />
        </div>
        <div class="elem-form">
          <input class="v-select"
            v-model="certificationData.organization" placeholder="Issuer Organization"
          requiredVal="certificationData.organization" />
        </div>
        <div class="elem-form">
          <input class="v-select"
            v-model="certificationData.url" placeholder="Url to Certification" />
        </div>

        <div class="cont-half">
          <div class="elem-form">
            <label>Date Issued:  </label>

            <datepicker
              class="elem-date"
              :minimum-view="'month'"
              :format="'MMM yyyy'"
              v-model="certificationData.issueDate"
              calendarClass="dateWrapper"
            />
          </div>
          <div class="elem-form">
            <label> Expiration Date:  </label>
            <datepicker
              class="elem-date"
              :minimum-view="'month'"
              :format="'MMM yyyy'"
              v-model="certificationData.expirationDate"
              calendarClass="dateWrapper right"

            />
          </div>
        </div>

        <div class="cont-half" v-if="!submitting">
          <div class="btn-green small  radius" @click="submit">Submit</div>
          <div class="btn-green small reverse radius" @click="cancel">Cancel</div>
        </div>

        <div class="cont-half" v-else>
          sending ...
        </div>

      </div>
    </div>
  </div>
</template>

<script>
// name organization issueDate expirationDate url
import Datepicker from 'vuejs-datepicker';
import validateMixin from '@/modules/mixins/validate';

export default {
  name: 'Certification',
  props: ['certification', 'editable'],
  mixins: [validateMixin],
  components: {
    Datepicker,
  },
  data() {
    const hasId = !this.certification._id;
    return {
      editing: hasId && this.editable,
      new: hasId,
      certificationData: { ...this.certification },
      submitting: false,
    };
  },
  methods: {
    toggleEdit() {
      if (this.editable) {
        this.editing = !this.editing;
        this.sending = false;
      }
    },
    deleteCertification() {
      this.$store.dispatch('talent/deleteCertification', this.certification._id);
    },

    cancel() {
      this.editing = false;
      this.certificationData = { ...this.certification };
      this.$emit('editClosed');
    },

    submit() {
      // if (this.validateFields()) {
      this.sending = true;
      if (this.new) {
        this.$store.dispatch('talent/addCertification', this.certificationData).then(() => {
          this.editing = false;
          this.$emit('editClosed');
          this.sending = false;
        });
      } else {
        this.$store.dispatch('talent/editCertification', this.certificationData).then(() => {
          this.editing = false;
          this.sending = false;
        });
      }
      // }
    },
  },

};
</script>

<style lang="scss" >
  @import "@/assets/scss/_variables.scss";
  .cert-view{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: space-between;
    padding: 10px;
    background-color: #ffffff;
    margin-bottom: 10px;
    &.radius {
      overflow: visible;
    }
  }

  .dateWrapper{
   top: -200px;
   left: 0px;
   &.right{
     left: -150px;
   }
  }
</style>
