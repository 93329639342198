<template>
  <div>
    <div class="title-section">
      Professional
    </div>
    <resume-entry
      v-for="(professionalEntry, index) in professionalEntries"
      :key="professionalEntry._id"
      :type="'professionalBG'"
      :objectData="professionalEntry"
      :editable="editable"
      :index="index"

    />
    <loading v-if="loading" />
    <div v-if="this.editable"
      class="btn-green full" @click="() => addNew('professionalBG')">Add new</div>

    <div class="title-section">
      Project
    </div>
    <resume-entry
      v-for="(projectEntry, index) in projectEntries"
      :key="projectEntry._id"
      :type="'projects'"
      :objectData="projectEntry"
      :editable="editable"
      :index="index"

    />
    <loading v-if="loading" />

    <div v-if="this.editable"
      class="btn-green full" @click="() => addNew('projects')">Add new</div>

    <div class="title-section">
      Academic
    </div>
    <resume-entry
      v-for="(academicEntry, index) in academicEntries"
      :key="academicEntry._id"
      :type="'academicBG'"
      :objectData="academicEntry"
      :editable="editable"
      :index="index"

    />
    <loading v-if="loading" />

    <div v-if="this.editable"
      class="btn-green full" @click="() => addNew('academicBG')">Add new</div>

  </div>
</template>

<script>
import ResumeEntry from '@/components/TalentComponents/resume/ResumeEntry.vue';
import Loading from '@/components/utilityPartials/_loading.vue';

export default {
  name: 'Resume',
  props: ['talentInfo', 'editable'],
  components: {
    ResumeEntry,
    Loading,
  },
  computed: {
    academicEntries() {
      if (this.editable) {
        return this.$store.getters['talent/academicBG'];
      }
      return this.talentInfo.academicBG;
    },
    projectEntries() {
      if (this.editable) {
        return this.$store.getters['talent/projects'];
      }
      return this.talentInfo.projects;
    },
    professionalEntries() {
      if (this.editable) {
        return this.$store.getters['talent/professionalBG'];
      }
      return this.talentInfo.professionalBG;
    },
    loading() {
      if (this.editable && !this.$store.state.talent.resumeRetrieved) {
        return true;
      }
      return false;
    },
  },
  methods: {
    addNew(type) {
      this.$store.dispatch('talent/addBlankResume', type);
    },

  },

};
</script>

<style lang="scss" scoped>
  @import "@/assets/scss/_variables.scss";
</style>
